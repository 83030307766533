<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="false"/>
        <v-container>
          <v-row class="mt-5">
            <v-col cols="8">
              <v-card
                style="color: #70445E;background-color: transparent;"
              >
                <v-card-title class="subtitle-2">TRABALHO SOCIAL</v-card-title>
                <v-card-text
                  class="black--text"
                  v-html="DadosTexto.Texto"
                ></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <div id="galeria" class="mt-5">
                <vue-picture-swipe :items="items"></vue-picture-swipe>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="false"/>
        <div class="container" id="topotrabalhosocial" style="margin-bottom: 20vh;">
          <span class="mb-2"><a href="#galeria" class="linkGaleria">Galeria</a></span>
          <v-card
            style="color: #70445E;background-color: transparent;"
          >
            <v-card-title class="subtitle-2">TRABALHO SOCIAL</v-card-title>
            <v-card-text
              class="black--text"
              v-html="DadosTexto.Texto"
            ></v-card-text>
          </v-card>
          <div id="galeria" class="mt-5">
            <vue-picture-swipe :items="items"></vue-picture-swipe>
          </div>
          <span><a href="#topotrabalhosocial" class="linkGaleria">Topo</a></span>
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
import AppBar from '../components/AppBar.vue'
export default {
  name: 'TrabalhoSocial',
  components: {
    AppBar
  },
  data () {
    return {
      DadosTexto: {
        Titulo: "TRABALHO SOCIAL",
        Texto: `Como educadora voluntária, Daniela Laubé é a responsável pela multiplicação de poesia e literatura na Associação Benficente Policena Barbosa. Na Casa Bem Me Quer, no contraturno da jornada escolar, uma vez por semana, apresenta autores e textos a meninas entre 9 e 14 anos de idade, propondo exercícios de escrita.<br /><br />
                “Desejo que a poesia seja respiro para a realidade dura e que a expressão por meio da escrita seja uma ferramenta de transformação. Procuro estimulá-las a enxergar a importância no que dizem, no conteúdo de sua voz, muito mais do que na forma socialmente esperada desses dizeres.” (Daniela Laubé)<br /><br />
                A ideia é fomentar a reflexão crítica a partir da arte e incentivar o contato com literatura como recurso de posicionamento frente ao mundo em que vivem. Em especial, são reforçadas as dificuldades e conquistas que permeiam a trajetória de mulheres importantes (a exemplo de Cecília Meireles, Francisca Julia, Gilka Machado, Maya Angelou, Malala e muitas outras).<br /><br />
                “A minha presença, como escritora, sentada na mesma roda em que elas, torna a literatura fisicamente presente e a poesia possível. A escrita passa a ser um caminho.” (Daniela Laubé)
                <br /><br />
        `
      },
      index: null,
      items: [{
          src: 'https://cdn.discordapp.com/attachments/999130638261166213/999130724147941406/trabalho_social_1.jpg',
          thumbnail: 'https://cdn.discordapp.com/attachments/999130638261166213/999133764141719663/trabalho_social_4.jpg',
          w: 1170,
          h: 658
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999130638261166213/999130723355209818/trabalho_social_2.jpg',
          thumbnail: 'https://cdn.discordapp.com/attachments/999130638261166213/999133763730690119/trabalho_social_5.jpg',
          w: 2904,
          h: 2400
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999130638261166213/999130722864472124/trabalho_social_3.jpg',
          thumbnail: 'https://cdn.discordapp.com/attachments/999130638261166213/999133763944579142/trabalho_social_6.jpg',
          w: 3024,
          h: 4032
        }
      ]
    }
  },
  methods: {
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .linkGaleria {
      display: flex;
      flex-direction:row-reverse;
      text-decoration: none;
      color:#70445E;
    }
</style>
